<template>
  <div
    class="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-50"
    style="z-index: 99"
  >
    <div class="bg-white w-1/2 h-auto rounded-xl" :key="render">
      <div
        class="py-3 px-5 flex justify-between items-center border-b border-b-primary"
      >
        <div class="text-2xl">
          Payment
          <p class="text-sm">Invoice ID: #{{ invoiceID }}</p>
        </div>
        <div
          class="bg-red-300 p-3 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <close-icon />
        </div>
      </div>
      <div class="p-5">
        <div class="flex justify-between items-center border-b pb-2">
          <h3 class="font-bold text-gray-500 text-sm w-2/6">
            ITEM DESCRIPTION
          </h3>
          <h3 class="font-bold text-gray-500 text-sm w-2/6">
            PRICE (PART PAID, REMAINING)
          </h3>
          <h3 class="font-bold text-gray-500 text-sm w-2/6">ACTION</h3>
        </div>
        <div
          class="flex justify-between items-center py-2"
          v-for="item in items"
          :key="item.id"
        >
          <h3 class="w-2/6">
            {{ item.name }}
          </h3>
          <div class="w-2/6" v-if="item.name === 'Storage Fee'">
            <template v-if="dateDiff(item.created_at) < 0">
              {{ parseCash(item.amount * dateDiff(item.created_at)) }}
            </template>
            <template v-else>{{ parseCash(item.amount) }}</template>
            ({{ parseCash(item.amount_part_paid) }},
            <span v-if="item.amount_part_paid">{{
              parseCash(item.amount - item.amount_part_paid)
            }}</span
            >)
          </div>
          <div class="w-2/6" v-else>
            {{ parseCash(item.amount) }}
            ({{ parseCash(item.amount_part_paid) }}
            <span v-if="item.amount_part_paid">{{
              parseCash(item.amount - item.amount_part_paid)
            }}</span
            >)
          </div>
          <div class="w-2/6 flex gap-5">
            <span
              class="block text-center w-full bg-green-500 text-white text-xs p-2 rounded-lg"
              v-if="paid.includes(item.id)"
            >
              Paid
            </span>
            <template v-else>
              <button
                class="w-full bg-primary text-white text-xs p-2 rounded-lg cursor-pointer"
                @click="pay(item)"
              >
                Pay Now
              </button>
              <button
                class="w-full bg-gray-300 text-black text-xs p-2 rounded-lg cursor-pointer"
                @click="partPay(item)"
              >
                Pay Part
              </button>
            </template>
          </div>
        </div>
      </div>
      <div class="p-5 flex justify-end">
        <button
          class="bg-green-500 text-white px-5 py-3 rounded-lg cursor-pointer"
          v-if="items.length === paid.length"
          @click="$emit('completed')"
        >
          Mark Invoice as Paid
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { parseMoney } from "@/utils/helpers";
import { payForItem, partPayForItem } from "@/services/addmission";
import moment from "moment";

export default {
  name: "Payment",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    invoiceID: {
      type: String,
      required: true,
    },
  },
  computed: {
    invoice: {
      get() {
        return this.items;
      },
      set(val) {
        this.items = val;
      },
    },
  },
  data() {
    return {
      paid: [],
      render: 0,
    };
  },
  methods: {
    dateDiff(date) {
      const today = moment();
      return Math.max(0, moment.duration(today.diff(date)).asDays());
    },
    parseCash(value) {
      return parseMoney(value);
    },
    async partPay(item) {
      let amount = prompt("How much does the user want to part pay?");
      const payload = {
        amount: amount,
        invoice_items_id: item.id,
      };
      try {
        await partPayForItem(payload);
        this.$toast.success("Paid payment recorded");
        let invoice = this.invoice;
        invoice[this.invoice.findIndex((obj) => obj.id === item.id)] = {
          ...item,
          amount_part_paid: Number(amount) + Number(item.amount_part_paid),
        };
        this.items = invoice;
        this.render++;
      } catch (error) {
        if (error.response.status === 400) {
          this.$toast.info("Item already paid for.");
          this.paid.push(item.id);
          return;
        }
        this.$toast.error(error.response.data.message || error);
        console.log(error.response.status);
      }
    },
    async pay(item) {
      const payload = {
        corpse_id: item.corpse_id,
        invoice_id: item.invoice_id,
        invoice_items_id: item.id,
      };
      try {
        await payForItem(payload);
        this.paid.push(item.id);
      } catch (error) {
        if (error.response.status === 400) {
          this.$toast.info("Item already paid for.");
          this.paid.push(item.id);
          return;
        }
        this.$toast.error(error.response.data.message || error);
        console.log(error.response.status);
      }
    },
  },
};
</script>
